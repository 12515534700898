import React from 'react';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import Layout from '../components/layout';

export default function Contact() {
  return (
    <Layout>
      <div className="relative bg-white">
        <div className="absolute inset-0" />
        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Get in touch
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Call Mark to come out for a free consultation and written
                proposal.
              </p>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>777 Talbot Street</p>
                    <p>St. Thomas, ON N5P 1E3</p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+1 (519) 282-8044</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">mark@cosensauctions.com</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white lg:col-span-3">
            <div className=" mx-auto h-full w-full">
              <iframe
                id="map"
                className="h-full w-full"
                title="Cosens Auctions & Appraials"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23424.86774447637!2d-81.19215318669542!3d42.78605676626056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882e5e63e33df04d%3A0xb136280627dff946!2sCosens%20Auctions%20%26%20Appraisals!5e0!3m2!1sen!2sca!4v1651689700230!5m2!1sen!2sca"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
